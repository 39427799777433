.nav {
  border-bottom: 1px solid rgba(var(--color-border), var(--color-border-alpha));
  width: 100%;
  display: unset;
  z-index: 4;
  position: relative;
}

.nav>div:first-of-type {
  position: relative;
}

.nav .page-container {
  padding-top: var(--space-v-lg-int);
  padding-bottom: var(--space-v-lg-int);
}

.child-sticky-section {
  position: sticky;
  top: 0;
  z-index: 1;
  /* To ensure the child section stays on top */
  background-color: rgb(var(--color-background-flat));
  border-bottom: 1px solid rgba(var(--color-border), var(--color-border-alpha));
}
