.block {
  position: relative;
  padding-left: 40px;
  margin-left: -40px;
}

.block-controls {
  visibility: hidden;
  position: absolute;
  left: -8px;
  opacity: .8;
}

.active,
.block:hover .block-controls,
.block:focus-within .block-controls {
  visibility: visible;
}

.add-block {
  opacity: .0;
  transition: .2s;
  height: 56px;
  transition-behavior: ease-in;
  margin-top: -16px;
  margin-bottom: -16px;
}

.active,
.add-block:focus-within,
.add-block:hover {
  opacity: 1;
  height: 64px;
}

.stack-demo.s {
  gap: var(--space-v-section) !important;
}
