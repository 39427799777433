.section-example,
.section-example .section-example {
  background: rgba(var(--color-primary), .1);
}

.section-example>.container-bounds {
  padding: var(--ts-space-v) var(--ts-space-h);
  background: rgb(var(--color-background-flat));
  margin: 0px !important;
  overflow: hidden;
}

.section-example .container-bounds .text {
  background: rgb(var(--color-background-elevated));
}

.stack-demo-container .container {
  padding: 8px;
}

.stack-demo-container {
  flex: none !important;
}


.stack-demo {
  /*outline: 1px dotted rgba(var(--color-primary), .4);*/
  /*outline-offset: 3px;*/
  background: rgba(var(--color-primary), .05);
  transition-duration: 0s;
}
